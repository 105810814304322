@use 'sass:math';
@use 'sass:map';
@use 'unit';
@use 'breakpoints';

// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

/// Hide an element by default, only displaying it above a certain screen size.
/// @param {Keyword} $size - Breakpoint to use. **Must be a breakpoint defined in `$breakpoints`.**
@mixin show-for($size) {
	$size: map.get(breakpoints.$breakpoints, $size);
	$size: unit.zf-bp-to-em($size) - math.div(1, 16);

	@include breakpoints.breakpoint($size down) {
		display: none !important;
	}
}

/// Hide an element by default, only displaying it within a certain breakpoint.
/// @param {Keyword} $size - Breakpoint to use. **Must be a breakpoint defined in `$breakpoints`.**
@mixin show-for-only($size) {
	$lower-bound-size: map.get(breakpoints.$breakpoints, $size);
	$upper-bound-size: breakpoints.zf-map-next(breakpoints.$breakpoints, $size);

	// more often than not this will be correct, just one time round the loop it won't so set in scope here
	$lower-bound: unit.zf-bp-to-em($lower-bound-size) - math.div(1, 16);
	// test actual lower-bound-size, if 0 set it to 0em
	@if strip-unit($lower-bound-size) == 0 {
		$lower-bound: unit.zf-bp-to-em($lower-bound-size);
	}

	@if $upper-bound-size == null {
		@media screen and (max-width: $lower-bound) {
			display: none !important;
		}
	} @else {
		$upper-bound: unit.zf-bp-to-em($upper-bound-size);

		@media screen and (max-width: $lower-bound), screen and (min-width: $upper-bound) {
			display: none !important;
		}
	}
}

/// Show an element by default, and hide it above a certain screen size.
/// @param {Keyword} $size - Breakpoint to use. **Must be a breakpoint defined in `$breakpoints`.**
@mixin hide-for($size) {
	@include breakpoints.breakpoint($size) {
		display: none !important;
	}
}

/// Show an element by default, and hide it above a certain screen size.
/// @param {Keyword} $size - Breakpoint to use. **Must be a breakpoint defined in `$breakpoints`.**
@mixin hide-for-only($size) {
	@include breakpoints.breakpoint($size only) {
		display: none !important;
	}
}

@mixin foundation-visibility-classes {
	// Basic hiding classes
	.hide {
		display: none !important;
	}

	.invisible {
		visibility: hidden;
	}

	// Responsive visibility classes
	@each $size in breakpoints.$breakpoint-classes {
		@if $size != breakpoints.$zf-zero-breakpoint {
			.hide-for-#{$size} {
				@include hide-for($size);
			}

			.show-for-#{$size} {
				@include show-for($size);
			}
		}

		.hide-for-#{$size}-only {
			@include hide-for-only($size);
		}

		.show-for-#{$size}-only {
			@include show-for-only($size);
		}
	}

	// Landscape and portrait visibility
	.show-for-landscape,
	.hide-for-portrait {
		display: block !important;

		@include breakpoints.breakpoint(landscape) {
			display: block !important;
		}

		@include breakpoints.breakpoint(portrait) {
			display: none !important;
		}
	}

	.hide-for-landscape,
	.show-for-portrait {
		display: none !important;

		@include breakpoints.breakpoint(landscape) {
			display: none !important;
		}

		@include breakpoints.breakpoint(portrait) {
			display: block !important;
		}
	}
}
