@use 'global-variables' as vars;
@use 'mixins';
@use 'breakpoints';

/* stylelint-disable font-family-no-missing-generic-family-keyword  */
.iconfont::before {
	font-family: 'docent-icons';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^='icon-']::before,
[class*=' icon-']::before {
	display: inline;

	font-size: vars.$icon-size;
	font-family: docent-icons;
	font-style: normal;

	line-height: vars.$icon-size;
	text-decoration: inherit;

	background-image: none;
	background-repeat: repeat;
	background-position: 0 0;
}

@include breakpoints.breakpoint(desktop) {
	.icon-show {
		transition:
			transform 0.3s ease-out,
			opacity 0.3s;
	}

	.icon-show:hover {
		transform: translate(-5px);
	}

	.icon-hide {
		transition:
			transform 0.3s ease-out,
			opacity 0.3s;
	}

	.icon-hide:hover {
		transform: translate(5px);
	}
}

.icon-logo::before {
	content: '\0187';
}

.icon-arrow::before {
	content: '\0102';
}

.icon-arrow-l::before {
	content: '\0041';
}

.icon-arrow-d::before {
	content: '\1E0E';
}

.icon-arrow-up::before {
	content: '\0045';
}

.icon-arrow-down::before {
	content: '\1ECC';
}

.icon-arrow-open::before {
	content: '\1E2E';
}

.icon-arrow-left::before {
	content: '\1E82';
}

.icon-arrow-right::before {
	content: '\1E84';
}

.icon-arrow-close::before {
	content: '\1ECA';
}

.icon-toets::before {
	content: '\01CD';
}

.icon-toets-groot::before {
	content: '\1E62';
}

.icon-huiswerk::before {
	content: '\00C2';
}

.icon-menu::before {
	content: '\1EAC';
}

.icon-edit::before {
	content: '\00C4';
}

.icon-calender::before {
	content: '\01DE';
}

.icon-hide::before {
	content: '\00C0';
}

.icon-show::before {
	content: '\0042';
}

.icon-start::before {
	content: '\00CF';
}

.icon-deadline::before {
	content: '\1ED8';
}

.icon-delete::before {
	content: '\2C6D';
}

.icon-notificatie::before {
	content: '\00C3';
}

.icon-zoek::before {
	content: '\1E04';
}

.icon-add::before {
	content: '\0181';
}

.icon-exit::before {
	content: '\0243';
}

.icon-error::before {
	content: '\1E0C';
}

.icon-ok::before {
	content: '\0110';
}

.icon-alert::before {
	content: '\018A';
}

.icon-rooster::before {
	content: '\010C';
}

.icon-vandaag::before {
	content: '\0043';
}

.icon-studiewijzer::before {
	content: '\0044';
}

.icon-resultaten::before {
	content: '\00D4';
}

.icon-voortgangsdossier::before {
	content: '\0186';
}

.icon-examendossier::before {
	content: '\00D8';
}

.icon-bericht::before {
	content: '\0189';
}

.icon-check::before {
	content: '\1E12';
}

.icon-lesplan::before {
	content: '\00C9';
}

.icon-info::before {
	content: '\0228';
}

.icon-clock::before {
	content: '\00CA';
}

.icon-clock-error::before {
	content: '\1EC6';
}

.icon-drag::before {
	content: '\00CB';
}

.icon-weggestuurd::before {
	content: '\1EB8';
}

.icon-linked::before {
	content: '\00C8';
}

.icon-not-linked::before {
	content: '\0112';
}

.icon-url::before {
	content: '\0190';
}

.icon-check-weggestuurd::before {
	content: '\018E';
}

.icon-check-cross::before {
	content: '\01A9';
}

.icon-klas::before {
	content: '\1EBC';
}

.icon-klas-alt::before {
	content: '\014A';
}

.icon-voting::before {
	content: '\01B7';
}

.icon-duim::before {
	content: '\0046';
}

.icon-duim-outline::before {
	content: '\0191';
}

.icon-file::before {
	content: '\0047';
}

.icon-dupliceren::before {
	content: '\0194';
}

.icon-oog::before {
	content: '\01E6';
}

.icon-oog-onzichtbaar::before {
	content: '\0197';
}

.icon-terug-somtoday::before {
	content: '\0193';
}

.icon-uitloggen::before {
	content: '\0241';
}

.icon-jaaritems::before {
	content: '\1E24';
}

.icon-check-bookmark::before {
	content: '\0049';
}

.icon-check-zichtbaar::before {
	content: '\00CD';
}

.icon-check-onzichtbaar::before {
	content: '\0128';
}

.icon-more-options::before {
	content: '\01CF';
}

.icon-more-options-vertical::before {
	transform: rotate(180deg);

	content: '\01CF';
}

.icon-bijlage::before {
	content: '\00D2';
}

.icon-bijlage-toevoegen::before {
	content: '\00CE';
}

.icon-lesstof-huiswerk::before {
	content: '\0198';
}

.icon-radio-unchecked::before {
	content: '\00CC';
}

.icon-radio-checked::before {
	content: '\0196';
}

.icon-checkbox-disabled::before {
	content: '\022A';
}

.icon-invisible::before {
	content: '\0196';
}

.icon-upload::before {
	content: '\004A';
}

.icon-download::before {
	content: '\01D1';
}

.icon-refresh::before {
	content: '\004B';
}

.icon-opgegeven::before {
	content: '\004C';
}

.icon-zwevende-items::before {
	content: '\1E3C';
}

.icon-locked::before {
	content: '\1E36';
}

.icon-cadeau::before {
	content: '\004D';
}

.icon-blocks::before {
	content: '\1E3E';
}

.icon-list::before {
	content: '\004E';
}

.icon-smiley::before {
	content: '\1E46';
}

.icon-uren-uit::before {
	content: '\1E4A';
}

.icon-uren-dicht::before {
	content: '\1E44';
}

.icon-comment::before {
	content: '\019D';
}

.icon-settings::before {
	content: '\00D1';
}

.icon-reeks::before {
	content: '\004F';
}

.icon-methode::before {
	content: '\01B2';
}

.icon-sjabloon::before {
	content: '\00D3';
}

.icon-idee::before {
	content: '\00D6';
}

.icon-map::before {
	content: '\014C';
}

.icon-map-delete::before {
	content: '\1E52';
}

.icon-map-add::before {
	content: '\1E50';
}

.icon-map-move::before {
	content: '\01FE';
}

.icon-help::before {
	content: '\0050';
}

.icon-kopieer-naar::before {
	content: '\01A4';
}

.icon-synchroniseren::before {
	content: '\2C64';
}

.icon-gesynchroniseerd::before {
	content: '\00DC';
}

.icon-sjabloon-importeren::before {
	content: '\0051';
}

.icon-differentiatie::before {
	content: '\0053';
}

.icon-werkdruk::before {
	content: '\0160';
}

.icon-label-toevoegen::before {
	content: '\018F';
}

.icon-elo-preview::before {
	content: '\0054';
}

.icon-afgevinkt::before {
	content: '\1E70';
}

.icon-inleveropdracht::before {
	content: '\1E6C';
}

.icon-inleveropdracht-alt::before {
	content: '\01AC';
}

.icon-ms-teams::before {
	content: '\1E6E';
}

.icon-google-meet::before {
	content: '\0057';
}

.icon-video::before {
	content: '\01AE';
}

.icon-check-niet-kopieren::before {
	content: '\0244';
}

.icon-check-kopieren::before {
	content: '\0055';
}

.icon-archief::before {
	content: '\1EE4';
}

.icon-beheerder::before {
	content: '\0170';
}

.icon-archiveren::before {
	content: '\016A';
}

.icon-importeren::before {
	content: '\01B1';
}

.icon-sort-asc::before {
	content: '\0168';
}

.icon-sort-desc::before {
	content: '\0056';
}

.icon-filter::before {
	content: '\024C';
}

.icon-leerdoel::before {
	content: '\0052';
}

.icon-type::before {
	content: '\0051';
}

.icon-drag {
	cursor: drag;
}

.icon-smallest {
	font-size: vars.$icon-smallest;
}

.icon-small {
	font-size: vars.$icon-small;
}

.icon-medium {
	font-size: vars.$icon-medium;
}

.icon-large {
	font-size: vars.$icon-large;
}

.icon-arrow-open-close {
	transition: transform 0.25s ease-out;

	&::before {
		content: '\1ECA';
	}

	&.close {
		transform: rotateX(-180deg);
	}
}
