@use 'global-variables' as vars;
@use 'breakpoints';
@use 'visibility';

@include visibility.foundation-visibility-classes;

@mixin text-head {
	font-weight: 700;
	font-size: 20px;
}

@mixin text-content-bold {
	font-weight: 700;
	font-size: 14px;
}

@mixin text-content-semi {
	font-weight: 600;
	font-size: 14px;
}

@mixin text-content {
	font-weight: 400;
	font-size: 14px;
}

@mixin text-small-content-bold {
	font-weight: 700;
	font-size: 12px;
}

@mixin text-small-content-semi {
	font-weight: 600;
	font-size: 12px;
}

@mixin text-small-content {
	font-weight: 400;
	font-size: 12px;
}

@mixin text-label-bold {
	font-weight: 700;
	font-size: 10px;
}

@mixin text-label-semi {
	font-weight: 600;
	font-size: 10px;
}

@mixin text-ellipsis {
	overflow: hidden;

	white-space: nowrap;
	text-overflow: ellipsis;
}

@mixin label-pill {
	@include text-label-semi;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 24px;
	padding: 4px 10px;

	border-radius: vars.$br-label;
}

@mixin label-pill-lightest {
	@include label-pill;
	color: vars.$primary-1;

	background-color: vars.$background-6;
}

@mixin label-pill-lighter {
	@include label-pill;
	color: vars.$primary-2;

	background-color: vars.$primary-4;
}

@mixin hide-scrollbar {
	// Firefox
	scrollbar-width: none;
	// Edge
	-ms-overflow-style: none;

	//Chrome/Safari
	&::-webkit-scrollbar {
		width: 0;

		background: transparent;
	}
}

@mixin default-font {
	@include text-content;
	color: vars.$color-cl-typography-1;
	font-family: 'Open Sans', sans-serif;
	line-height: 20px;
}
