@use 'global-variables' as vars;
@use 'ngx-toastr/toastr';

.toast-message a,
.toast-message label {
	color: #fff;
}
.toast-message a:hover {
	color: #ccc;
	text-decoration: none;
}

.toast-top-center {
	top: 0;
	right: 0;
	width: 100%;
}
.toast-bottom-center {
	bottom: 0;
	right: 0;
	width: 100%;
}
.toast-top-full-width {
	top: 0;
	right: 0;
	width: 100%;
}
.toast-bottom-full-width {
	bottom: 0;
	right: 0;
	width: 100%;
}
.toast-top-left {
	top: 12px;
	left: 12px;
}
.toast-bottom-right {
	right: 12px;
	bottom: 12px;
}
.toast-bottom-left {
	bottom: 12px;
	left: 12px;
}
#toast-container {
	pointer-events: none;
	position: fixed;
	z-index: 99999;
}
#toast-container * {
	box-sizing: border-box;
}
#toast-container > div.toast-custom {
	padding: 15px;
	color: #030303;
}
#toast-container > div.toast-custom .toast-close-button {
	color: #999 !important;
}
#toast-container.toast-bottom-center > div,
#toast-container.toast-top-center > div {
	width: 300px;
	margin: auto;
}
#toast-container.toast-bottom-full-width > div,
#toast-container.toast-top-full-width > div {
	width: 96%;
	margin: auto;
}
.toast {
	background-color: #fff;
	pointer-events: auto;
}
.toast-progress {
	position: absolute;
	left: 0;
	bottom: 0;
	height: 4px;
	background-color: #000;
	opacity: 0.4;
}
@media (max-width: 240px) {
	#toast-container > div {
		padding: 8px 8px 8px 50px;
		width: 11em;
	}
	#toast-container .toast-close-button {
		right: -0.2em;
		top: -0.2em;
	}
}
@media (min-width: 241px) and (max-width: 480px) {
	#toast-container > div {
		padding: 8px 8px 8px 50px;
		width: 18em;
	}
	#toast-container .toast-close-button {
		right: -0.2em;
		top: -0.2em;
	}
}
@media (min-width: 481px) and (max-width: 768px) {
	#toast-container > div {
		padding: 15px 15px 15px 50px;
		width: 25em;
	}
}

/* Hieronder staan de stukken CSS die door ons zijn aangepast */
#toast-container > div {
	position: relative;
	overflow: hidden;
	margin: 0 0 12px;
	padding: 15px 15px 15px 54px;
	width: 350px;
	border-radius: 3px 3px 3px 3px;
	background-position: 15px;
	background-repeat: no-repeat;
	box-shadow: 0 3px 12px #999;
}

.toast-title {
	font-weight: 700;
	font-size: 14pt;
	margin-bottom: 5px;
}

.toast-message {
	word-wrap: break-word;
	font-size: 11pt;
	color: #99a8c0;
}

.toast-top-right {
	top: vars.$header-height + 0.75rem;
	right: 1.25rem;
}

@media (max-width: 768px) {
	.toast-top-right {
		top: 50px;
		right: 0;
		left: 5%;
	}

	#toast-container > div {
		width: 95%;
	}
}

.toast-close-button {
	span {
		display: block;
		opacity: 0.25;
		width: 1.5rem;
		height: 1.5rem;
		font-size: 40px;
		line-height: 36px;
		padding: 0 2rem 1rem 0;
	}
}

.toast-error {
	background-color: vars.$color-cl-error-3;
	border: 1px solid vars.$color-cl-error-1;
	background-image: url('/assets/img/cross.svg');
	background-size: 25px 25px;

	.toast-message {
		color: vars.$color-cl-error-1;
	}

	.toast-close-button {
		span {
			color: vars.$color-cl-error-1;
		}
	}
}

.toast-warning {
	background-color: vars.$color-cl-warning-2;
	border: 1px solid vars.$accent-warning-1;
	background-image: url('/assets/img/toast-warning.svg');
	background-size: 25px 25px;

	.toast-message {
		color: vars.$accent-warning-1;
	}

	.toast-close-button {
		span {
			color: vars.$accent-warning-1;
		}
	}
}

.toast-info {
	background-color: vars.$accent-info-2;
	border: 1px solid vars.$accent-info-1;
	background-image: url('/assets/img/toast-info.svg');
	background-size: 25px 25px;

	.toast-message {
		color: vars.$accent-info-1;
	}

	.toast-close-button {
		span {
			color: vars.$accent-info-1;
		}
	}
}

.toast-success {
	background-color: vars.$accent-positive-3;
	border: 1px solid vars.$accent-positive-1;
	background-image: url('/assets/img/check.svg');
	background-size: 25px 25px;

	.toast-message {
		color: vars.$accent-positive-1;
	}

	.toast-close-button {
		span {
			color: vars.$accent-positive-1;
		}
	}
}
