@use 'global-variables' as vars;
@use 'mixins';
@use 'breakpoints';

.label-pill-dark {
	@include mixins.label-pill;
	color: vars.$color-cl-typography-5;

	background-color: vars.$primary-3;
}

.label-pill-light {
	@include mixins.label-pill;
	color: vars.$color-cl-typography-5;

	background-color: vars.$primary-1;
}

.label-pill-lighter {
	@include mixins.label-pill-lighter;
}

.label-pill-lightest {
	@include mixins.label-pill-lightest;
}

.label-pill-positive {
	@include mixins.label-pill;
	color: vars.$accent-positive-1;

	background-color: vars.$accent-positive-3;
}

.label-pill-typography {
	@include mixins.label-pill;
	color: vars.$color-cl-typography-3;

	background-color: vars.$color-cl-background-4;
}

.label-pill-warning {
	@include mixins.label-pill;
	color: vars.$background-6;

	background-color: vars.$accent-warning-1;
}

.label-pill-secondary {
	@include mixins.label-pill;
	color: vars.$secondary-2;

	background-color: vars.$secondary-3;
}

.label-block {
	@include mixins.text-content-bold;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 24px;
	padding: 1px 8px;

	color: vars.$background-6;

	background-color: vars.$secondary-1;
	border-radius: vars.$br-normal;
}

@include breakpoints.breakpoint(desktop) {
	.label-pill-warning,
	.label-pill-typography,
	.label-pill-dark,
	.label-pill-light,
	.label-pill-lighter,
	.label-pill-lightest,
	.label-pill-positive {
		height: 20px;
	}
}
