// Do not add EOT fonts, safari does not like them.
@use 'mixins';

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

@font-face {
	font-weight: normal;
	font-family: 'docent-icons';
	src: url('/assets/fonts/docent-icons/docent-icons.woff') format('woff');
}

.text-head {
	@include mixins.text-head;
}

.text-content-bold {
	@include mixins.text-content-bold;
}

.text-content-semi {
	@include mixins.text-content-semi;
}

.text-content {
	@include mixins.text-content;
}

.text-small-content-bold {
	@include mixins.text-small-content-bold;
}

.text-small-content-semi {
	@include mixins.text-small-content-semi;
}

.text-small-content {
	@include mixins.text-small-content;
}

.text-label-bold {
	@include mixins.text-label-bold;
}

.text-label-semi {
	@include mixins.text-label-semi;
}
