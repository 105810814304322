@use 'global-variables' as vars;
@use 'mixins';
@use 'breakpoints';

textarea,
input[type='text'],
input[type='submit'] {
	/* stylelint-disable-next-line property-no-vendor-prefix */
	-webkit-appearance: none; // verwijderd safari shadows
}

textarea {
	padding: 5px;

	font-size: 9pt;

	resize: none;
}

input,
textarea {
	font-family: inherit;

	border: vars.$border-contrast;
	border-radius: vars.$br-normal;
	box-shadow: none;

	/* stylelint-disable selector-no-vendor-prefix */
	&::-webkit-input-placeholder {
		color: vars.$color-cl-typography-3;
	}

	&::-moz-placeholder {
		color: vars.$color-cl-typography-3;
	}

	&::placeholder {
		color: vars.$color-cl-typography-3;
	}

	&:focus {
		border: vars.$border-primary; // overwrite foundation
		box-shadow: vars.$shadow-form-active;
	}

	&:hover:not(:disabled) {
		border: vars.$border-primary;
	}

	&:disabled {
		color: vars.$color-cl-typography-5;
		background-color: vars.$color-cl-background-1;
	}
}

input[type='time'] {
	padding: 0 16px;
}

form {
	label {
		@include mixins.text-content-semi;
		color: vars.$color-cl-typography-2;
	}

	input {
		height: 48px;
		padding-left: 16px;
	}

	input,
	textarea {
		width: 100%;
		margin-top: 10px;
		margin-bottom: 10px;
		padding-right: 16px;
		padding-left: 16px;

		font-size: 16px;
		line-height: normal;

		box-shadow: none;

		&.invalid {
			border: vars.$border-error;
		}
	}

	input[type='radio'] {
		width: initial;
	}

	input[type='time'] {
		background-color: vars.$background-6;
	}

	button {
		border: 0;
	}
}

@include breakpoints.breakpoint(desktop) {
	form {
		input {
			height: 40px;
		}
	}
}
