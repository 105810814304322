@use 'global-variables' as vars;

.legenda-options {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	padding: 1rem;
	gap: 0.5rem;

	.option {
		display: flex;
		gap: 0.5rem;
		align-items: center;

		.legenda {
			width: 0.5rem;
			height: 1rem;
			position: relative;

			&.pill {
				width: 2.1875rem;
				height: 1.25rem;
				border-radius: 0.625rem;
				text-align: center;
				font-size: 10px;
				font-weight: 700;
				&::before {
					content: '1';
				}
				&:not(.blocky) {
					color: vars.$color-cl-typography-4;
				}
				&.blocky {
					border-radius: 3px;
					border-width: 1px;
					border-style: solid;
				}
			}

			&.geen {
				border: solid 1px vars.$color-cl-background-4;
			}
		}

		.label {
			white-space: nowrap;
		}

		.vink {
			color: vars.$bar-geslaagd;
			background-color: vars.$color-cl-positive-2;
			border-color: vars.$bar-geslaagd;
			border-radius: 6px;
			border-width: 1px;
			border-style: solid;
			font-family: docent-icons;
			font-size: 7px;
			line-height: 10px;
			position: absolute;
			top: 0;
			right: 0;
			height: 12px;
			width: 12px;
			transform: translate(6px, -6px);
		}
	}
}
