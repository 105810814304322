@use 'mixins';
@use 'global-variables' as vars;

// Specifieke styling voor PrinceXML (PDF-export)
@media print {
	@page {
		size: A4;
		margin: 1cm;
		prince-shrink-to-fit: auto;

		@bottom-right {
			@include mixins.default-font;
			content: counter(page);
		}

		@bottom-left {
			@include mixins.default-font;
		}
	}
}

.princexml {
	body {
		background: none;
		& > * {
			margin-bottom: 2rem;
		}
	}

	[data-princexml-ignore],
	[class*='sort-icon'] {
		display: none !important; // Toon deze elementen nooit
	}

	dl.filters {
		display: flex;
		flex-flow: row wrap;

		dt {
			flex-basis: 20%;
		}

		dd {
			flex-basis: 70%;
			flex-grow: 1;
		}
	}
}

.html2canvas-header {
	position: absolute;
	left: -9999999px;
	& > *:not(:first-child) {
		margin-top: 1rem;
	}

	dl.filters {
		display: grid;
		grid-template-columns: minmax(max-content, 10rem) auto;
		column-gap: 0.5rem;
	}
}

.princexml,
.html2canvas-header {
	.legenda-options {
		display: flex;
		flex-direction: column;
		padding: 0;

		.option {
			display: flex;
			align-items: center;

			.legenda {
				width: 0.5rem;
				height: 1rem;
				margin-right: 0.5rem;

				&.geen {
					border: solid 1px vars.$color-cl-background-4;
				}
			}

			.label {
				white-space: nowrap;
			}
		}
	}
}
