@use 'global-variables' as vars;

.svg-legenda {
	background: url('/assets/img/legenda.svg') no-repeat;
	width: 1.25rem;
	height: 1.25rem;
	margin-left: -0.25rem;
	margin-top: 0.15rem;
}

.svg-arrow-down {
	background: url('/assets/img/arrow-down.svg') no-repeat;
	width: 1.25rem;
	height: 1.25rem;
	background-size: contain;
}

.svg-arrow-up {
	background: url('/assets/img/arrow-down.svg') no-repeat;
	width: 1.25rem;
	height: 1.25rem;
	background-size: contain;
	transform: rotate(180deg);
}

.svg-info {
	mask: url('/assets/img/Info.svg') no-repeat;
	background-color: vars.$color-cl-primary-1;
	width: 1rem;
	height: 1rem;
	mask-size: contain;

	&:hover {
		cursor: pointer;
		background-color: vars.$color-cl-primary-2;
	}
}

.svg-graph-dalend-warning {
	background: url('/assets/img/GraphDalendWarning.svg') no-repeat;
	width: 0.75rem;
	height: 0.75rem;
	background-size: contain;
}

.svg-graph-dalend-error {
	background: url('/assets/img/GraphDalendError.svg') no-repeat;
	width: 0.75rem;
	height: 0.75rem;
	background-size: contain;
}

.svg-graph-stijgend {
	background: url('/assets/img/GraphStijgend.svg') no-repeat;
	width: 0.75rem;
	height: 0.75rem;
	background-size: contain;
}

.svg-add-groepering {
	mask-image: url('/assets/img/plus.svg');
	background-color: vars.$color-cl-typography-3;
	width: 1.25rem;
	height: 1.25rem;
	mask-size: contain;
}

.svg-plus-button {
	mask-image: url('/assets/img/plus.svg');
	background-color: vars.$color-cl-primary-1;
	width: 1.25rem;
	height: 1.25rem;
	mask-size: contain;
}

.svg-plus-sign {
	background: url('/assets/img/plus.svg') no-repeat center;
	width: 0.5rem;
	height: 0.5rem;
	background-size: 60% auto;
}

.svg-minus-sign {
	background: url('/assets/img/minus.svg') no-repeat center;
	width: 0.75rem;
	height: 0.75rem;
	background-size: 60% auto;
}

.svg-alert {
	background: url('/assets/img/alert.svg') no-repeat center;
	width: 1rem;
	height: 1rem;
	background-size: contain;
}

.svg-bookmark {
	background: url('/assets/img/Bookmark.svg') no-repeat center;
	width: 1.5rem;
	height: 1.5rem;
	background-size: contain;
}

.svg-community {
	background: url('/assets/img/Community.svg') no-repeat center;
	width: 1.5rem;
	height: 1.5rem;
	background-size: contain;
}

.svg-help {
	background: url('/assets/img/Help.svg') no-repeat center;
	width: 1.5rem;
	height: 1.5rem;
	background-size: contain;
}

.svg-releasenotes {
	background: url('/assets/img/ReleaseNotes.svg') no-repeat center;
	width: 1.5rem;
	height: 1.5rem;
	background-size: contain;
}

.svg-settings {
	background: url('/assets/img/SettingsMenu.svg') no-repeat center;
	width: 1.5rem;
	height: 1.5rem;
	background-size: contain;
}

.svg-add-bookmark {
	mask-image: url('/assets/img/AddBookmark.svg');
	background-color: vars.$color-cl-primary-1;
	width: 1rem;
	height: 1rem;
	mask-size: contain;

	&-grey {
		background-color: vars.$color-cl-typography-5;
	}
}

.svg-add-folder {
	mask-image: url('/assets/img/AddFolder.svg');
	background-color: vars.$color-cl-primary-1;
	width: 1rem;
	height: 1rem;
	mask-size: contain;

	&-grey {
		background-color: vars.$color-cl-typography-5;
	}
}

.svg-add-user {
	mask-image: url('/assets/img/AddUser.svg');
	background-color: vars.$color-cl-primary-1;
	width: 1rem;
	height: 1rem;
	mask-size: contain;

	&-grey {
		background-color: vars.$color-cl-typography-5;
	}
}

.svg-folder {
	background: url('/assets/img/Folder.svg') no-repeat center;
	width: 1rem;
	height: 1em;
	background-size: contain;
}

.svg-history {
	mask: url('/assets/img/History.svg') no-repeat center / contain;
	background-color: vars.$color-cl-primary-1;
	width: 2rem;
	height: 2rem;
	mask-size: contain;
}

.svg-meatball-menu {
	mask: url('/assets/img/MeatballMenu.svg') no-repeat center / contain;
	background-color: vars.$color-cl-primary-1;
	width: 1.5rem;
	height: 1rem;

	&.large {
		height: 1.5rem;
	}
}

.svg-meatball-menu-vertical {
	mask: url('/assets/img/MeatballMenuVertical.svg') no-repeat center / contain;
	background-color: vars.$color-cl-primary-1;
	width: 1.5rem;
	height: 1rem;

	&.large {
		height: 1.5rem;
	}
}

.svg-meatball-menu-grey {
	mask: url('/assets/img/MeatballMenu.svg') no-repeat center / contain;
	background-color: vars.$color-cl-typography-5;
	width: 0.5rem;
	height: 0.5rem;
}

.svg-share {
	background: url('/assets/img/Share.svg') no-repeat center;
	width: 1.5rem;
	height: 1.5rem;
	background-size: contain;
}

.svg-switch {
	background: url('/assets/img/Switch.svg') no-repeat center;
	width: 1.5rem;
	height: 1.5rem;
	background-size: contain;
}

.svg-export {
	background: url('/assets/img/Export.svg') no-repeat center;
	width: 1.5rem;
	height: 1.5rem;
	background-size: contain;
}

.svg-search {
	background: url('/assets/img/Search.svg') no-repeat center;
	width: 1.25rem;
	height: 1.25rem;
	background-size: contain;
}

.svg-sluiten {
	background: url('/assets/img/Sluiten.svg') no-repeat center;
	width: 0.75rem;
	height: 0.75rem;
	background-size: contain;
}

.svg-chevron-left {
	mask-size: contain;
	mask-image: url('/assets/img/ChevronLeft.svg');
	mask-repeat: no-repeat;
	mask-position: center;

	background-image: none;
	background-color: vars.$color-cl-primary-1;

	width: 1.5rem;
	height: 1.5rem;

	&.small {
		width: 1.25rem;
		height: 1.25rem;
	}
}

.svg-chevron-double-left {
	mask-size: contain;
	mask-image: url('/assets/img/ChevronLeft-Double.svg');
	mask-repeat: no-repeat;
	mask-position: center;

	background-image: none;
	background-color: vars.$color-cl-primary-1;

	width: 1.5rem;
	height: 1.5rem;
}

.svg-chevron-right {
	mask-size: contain;
	mask-image: url('/assets/img/ChevronRight.svg');
	mask-repeat: no-repeat;
	mask-position: center;

	background-image: none;
	background-color: vars.$color-cl-primary-1;

	width: 1.5rem;
	height: 1.5rem;

	&.small {
		width: 1rem;
		height: 1rem;
	}

	&.grey {
		background-color: vars.$color-cl-typography-5;
	}
}

.svg-chevron-double-right {
	mask-size: contain;
	mask-image: url('/assets/img/ChevronRight-Double.svg');
	mask-repeat: no-repeat;
	mask-position: center;

	background-image: none;
	background-color: vars.$color-cl-primary-1;

	width: 1.5rem;
	height: 1.5rem;
}

.svg-chevron-up {
	background-image: none;
	background-color: vars.$color-cl-primary-1;
	mask-size: contain;
	mask-image: url('/assets/img/ChevronUp.svg');
	width: 0.75rem;
	height: 0.75rem;

	&.large {
		width: 1rem;
		height: 1rem;
	}
}

.svg-chevron-double-up {
	mask-size: contain;
	mask-image: url('/assets/img/ChevronUp-Double.svg');
	mask-repeat: no-repeat;
	mask-position: center;

	background-image: none;
	background-color: vars.$color-cl-primary-1;

	width: 1.5rem;
	height: 1.5rem;
}

.svg-chevron-down {
	background-image: none;
	background-color: vars.$color-cl-typography-5;
	mask-size: contain;
	mask-image: url('/assets/img/ChevronDown.svg');
	width: 0.75rem;
	height: 0.75rem;

	&.large {
		width: 1rem;
		height: 1rem;
	}
}

.svg-chevron-double-down {
	mask-size: contain;
	mask-image: url('/assets/img/ChevronDown-Double.svg');
	mask-repeat: no-repeat;
	mask-position: center;

	background-image: none;
	background-color: vars.$color-cl-primary-1;

	width: 1.5rem;
	height: 1.5rem;
}

.svg-delete {
	background-image: none;
	background-color: vars.$color-cl-typography-5;
	mask-size: contain;
	mask-image: url('/assets/img/Delete.svg');
	width: 1rem;
	height: 1rem;
}

.svg-unread {
	background: url('/assets/img/unread.svg') no-repeat center;
	width: 0.5rem;
	height: 0.5rem;
	background-size: contain;
}

.svg-sort-asc {
	background-image: none;
	background-color: vars.$color-cl-typography-3;

	mask-size: contain;
	mask-image: url('/assets/img/sort-asc.svg');
	mask-repeat: no-repeat;
	mask-position: center;

	width: 1rem;
	height: 1rem;

	&.grey {
		background-color: vars.$color-cl-typography-5;
	}
}

.svg-sort-desc {
	background-image: none;
	background-color: vars.$color-cl-typography-3;

	mask-size: contain;
	mask-image: url('/assets/img/sort-desc.svg');
	mask-repeat: no-repeat;
	mask-position: center;

	width: 1rem;
	height: 1rem;

	&.grey {
		background-color: vars.$color-cl-typography-5;
	}
}

.svg-calendar {
	background: url('../assets/img/Calendar.svg') no-repeat center;
	width: 1.25rem;
	height: 1.25rem;
	background-size: contain;
}

.svg-magister {
	background: url('../assets/img/Magister.svg') no-repeat center;
	width: 1.5rem;
	height: 1.5rem;
	background-size: contain;
}

.svg-somtoday {
	background: url('../assets/img/Somtoday.svg') no-repeat center;
	width: 1.5rem;
	height: 1.5rem;
	background-size: contain;
}

.svg-new-page {
	background: url('../assets/img/NewPage.svg') no-repeat center;
	width: 1.25rem;
	height: 1.25rem;
	background-size: contain;
}

.svg-full-screen {
	background: url('/assets/img/FullScreen.svg') no-repeat center;
	width: 1.25rem;
	height: 1.25rem;
	background-size: contain;
}

.svg-full-screen-off {
	background: url('/assets/img/FullScreenOff.svg') no-repeat center;
	width: 1.25rem;
	height: 1.25rem;
	background-size: contain;
}

.svg-check {
	background-image: none;
	background-color: vars.$color-cl-primary-1;
	mask-size: contain;
	mask-image: url('/assets/img/check.svg');
	width: 1.25rem;
	height: 1.25rem;
}

.svg-cross {
	background-image: none;
	background-color: vars.$color-cl-primary-1;
	mask-size: contain;
	mask-image: url('/assets/img/cross.svg');
	width: 1.25rem;
	height: 1.25rem;
}

.svg-refresh {
	background-image: none;
	background-color: vars.$color-cl-primary-1;
	mask-size: contain;
	mask-image: url('/assets/img/Refresh.svg');
	width: 1.25rem;
	height: 1.25rem;
}

.svg-etl-succesvol {
	background: url('/assets/img/check.svg') no-repeat center;
	width: 1.25rem;
	height: 1.25rem;
	background-size: contain;
	flex: none;
}

.svg-error {
	background: url('/assets/img/cross.svg') no-repeat center;
	width: 1.25rem;
	height: 1.25rem;
	background-size: contain;
	flex: none;
}

.svg-warning {
	background: url('/assets/img/toast-warning.svg') no-repeat center;
	width: 1.25rem;
	height: 1.25rem;
	background-size: contain;
	flex: none;
}

.svg-bezig {
	background: url('/assets/img/Bezig.svg') no-repeat center;
	width: 1.25rem;
	height: 1.25rem;
	background-size: contain;
	flex: none;
}

.svg-gepland {
	background: url('/assets/img/Gepland.svg') no-repeat center;
	width: 1.25rem;
	height: 1.25rem;
	background-size: contain;
	flex: none;
}
