@use 'global-variables' as vars;
@use 'svg';

@mixin bar-color($class, $color, $fillColor: null) {
	div.#{$class} {
		background-color: $color;
	}
	rect.#{$class} {
		fill: $color;
	}
	polyline.#{$class} {
		stroke: $color;

		&-fill {
			fill: $fillColor;
			stroke: none;
		}
	}
	circle.#{$class} {
		fill: $color;

		&-fill {
			fill: $fillColor;
		}
	}
}

@mixin cell-color($class, $color) {
	span.#{$class} {
		border-color: $color;
		&:hover {
			background-color: rgba($color, 0.1);
		}
	}
	span.#{$class}-prognose:not(.empty) {
		border-style: dashed;
		border-color: $color;
		&:hover {
			background-color: rgba($color, 0.1);
		}
	}
	div.#{$class}.blocky {
		border-color: $color;
		background-color: rgba($color, 0.1);
		color: $color;
	}
	rect.#{$class}.blocky {
		stroke: $color;
		fill: rgba($color, 0.1);
	}
	rect.#{$class}.blocky + text {
		fill: $color;
	}
}

@mixin text-color($class, $color) {
	div.#{$class} {
		color: $color;
	}
	td.#{$class} {
		color: $color;
	}
}

@mixin border-color($class, $color) {
	div.#{$class} {
		border-color: $color !important;
	}
	td.#{$class} {
		border-color: $color !important;
	}
}

@include bar-color(dashboard-default, vars.$bar-default);
@include bar-color(dashboard-prognose, rgba(vars.$bar-default, 0.5));

.dashboard-kenmerk {
	rect {
		// Default fill grijs ipv zwart (zichtbaar tijdens wisselen van kenmerk)
		fill: vars.$bar-geenkenmerk;
	}

	@include bar-color(apcg, vars.$bar-kenmerk);
	@include bar-color(examenopstroom, vars.$bar-kenmerk);
	@include bar-color(extern, vars.$color-cl-chart-roze);
	@include bar-color(extern-tussentijds, vars.$color-cl-chart-paars);
	@include bar-color(intern, vars.$bar-cijfer-10);
	@include bar-color(intern-tussentijds, vars.$bar-cijfer-9);
	@include bar-color(lwoo, vars.$bar-kenmerk);
	@include bar-color(vavo, vars.$cumlaude-geel);
	@include bar-color(intern-vavo, vars.$cumlaude-geel);
	@include bar-color(vavo-tussentijds, vars.$cumlaude-oranje);

	@include bar-color(geen, vars.$bar-geenkenmerk);
	@include bar-color(geen-apcg, vars.$bar-geenkenmerk);
	@include bar-color(geen-examenopstroom, vars.$bar-geenkenmerk);
	@include bar-color(geen-instroom, vars.$bar-geenkenmerk);
	@include bar-color(geen-lwoo, vars.$bar-geenkenmerk);
	@include bar-color(geen-uitstroom, vars.$bar-geenkenmerk);
	@include bar-color(geen-vavo, vars.$bar-geenkenmerk);
}

.dashboard-afwezigheid {
	@include bar-color(geoorloofde-afwezigheid, vars.$bar-geoorloofd);
	@include bar-color(ongeoorloofde-afwezigheid, vars.$bar-ongeoorloofd);
}

.dashboard-afwezigheid-historie {
	@include bar-color(geoorloofde-afwezigheid, vars.$bar-doublure);
	@include bar-color(ongeoorloofde-afwezigheid, vars.$color-cl-chart-roze);
	@include bar-color(afwezigheid-totaal, vars.$color-cl-primary-1);
}

.dashboard-doorstroom,
.dashboard-matrix,
.dashboard-cohortdetails,
.ds_nm_idu,
.cf_map_idu {
	@include bar-color(opstroom, vars.$bar-opstroom);
	@include bar-color(opstroom-prognose, rgba(vars.$bar-opstroom, 0.5));
	@include bar-color(doorstroom, vars.$bar-doorstroom);
	@include bar-color(doorstroom-prognose, rgba(vars.$bar-doorstroom, 0.5));
	@include bar-color(geslaagd, vars.$bar-geslaagd);
	@include bar-color(geslaagd-prognose, rgba(vars.$bar-geslaagd, 0.5));
	@include bar-color(afstroom, vars.$bar-afstroom);
	@include bar-color(afstroom-prognose, rgba(vars.$bar-afstroom, 0.5));
	@include bar-color(doublure, vars.$bar-doublure);
	@include bar-color(doublure-prognose, rgba(vars.$bar-doublure, 0.5));
	@include bar-color(afgewezen, vars.$bar-afgewezen);
	@include bar-color(afgewezen-prognose, rgba(vars.$bar-afgewezen, 0.5));
	@include bar-color(onbekend, vars.$bar-onbekend);
	@include bar-color(onbekend-prognose, rgba(vars.$bar-onbekend, 0.5));
	@include bar-color(onbekend-alt, vars.$bar-onbekend-alt);
	@include bar-color(geen, vars.$bar-geen-doorstroom);
	@include bar-color(instroom, vars.$bar-onbekend-alt);
}

.dashboard-einduitstroom {
	@include bar-color(vervolgonderwijs, vars.$bar-doorstroom);
	@include bar-color(arbeid, vars.$bar-doublure);
	@include bar-color(onbekend, vars.$bar-onbekend);
}
.dashboard-tussentijdse-uitstroom {
	@include bar-color(regulier-onderwijs, vars.$bar-doorstroom);
	@include bar-color(speciaal-onderwijs, vars.$bar-doublure);
	@include bar-color(onbekend, vars.$bar-onbekend);
}

.dashboard-geslaagd,
.ds_map_examenstatus_met_prognose {
	@include bar-color(voortijdig-teruggetrokken, vars.$bar-geslaagd-voortijdig-teruggetrokken);
	@include bar-color(tijdens-examen-teruggetrokken, vars.$bar-geslaagd-tijdens-examen-teruggetrokken);
	@include bar-color(afgewezen, vars.$bar-geslaagd-afgewezen);
	@include bar-color(afgewezen-prognose, rgba(vars.$bar-geslaagd-afgewezen, 0.5));
	@include bar-color(geslaagd, vars.$bar-geslaagd-geslaagd);
	@include bar-color(geslaagd-prognose, rgba(vars.$bar-geslaagd-geslaagd, 0.5));
	@include bar-color(overig, vars.$bar-geslaagd-overig);
}

.dashboard-overgang,
.x_met_overgang {
	@include bar-color(met-overgang, vars.$bar-overgang-met);
	@include bar-color(zonder-overgang, vars.$bar-overgang-zonder);
}

.dashboard-plaatsing-advies {
	@include bar-color(d-2-5-niveaus-lager-dan-advies, vars.$bar-verschil--25);
	@include bar-color(verschil--25, vars.$bar-verschil--25);

	@include bar-color(d-2-0-niveaus-lager-dan-advies, vars.$bar-verschil--20);
	@include bar-color(verschil--20, vars.$bar-verschil--20);

	@include bar-color(d-1-5-niveaus-lager-dan-advies, vars.$bar-verschil--15);
	@include bar-color(verschil--15, vars.$bar-verschil--15);

	@include bar-color(d-1-niveau-lager-dan-advies, vars.$bar-verschil--10);
	@include bar-color(d-1-0-niveaus-lager-dan-advies, vars.$bar-verschil--10);
	@include bar-color(verschil--10, vars.$bar-verschil--10);
	@include bar-color(verschil--10-prognose, rgba(vars.$bar-verschil--10, 0.5));

	@include bar-color(d-0-5-niveaus-lager-dan-advies, vars.$bar-verschil--5);
	@include bar-color(verschil--5, vars.$bar-verschil--5);

	@include bar-color(d-0-niveau-gelijk-aan-advies, vars.$bar-verschil-0);
	@include bar-color(niveau-gelijk-aan-advies, vars.$bar-verschil-0);
	@include bar-color(verschil-0, vars.$bar-verschil-0);
	@include bar-color(verschil-0-prognose, rgba(vars.$bar-verschil-0, 0.5));

	@include bar-color(d-0-5-havo-vwo-plaatsing-bij-havo-advies, vars.$bar-verschil-5hv);
	@include bar-color(verschil-5hv, vars.$bar-verschil-5hv);
	@include bar-color(verschil-5hv-prognose, rgba(vars.$bar-verschil-5hv, 0.5));

	@include bar-color(d-0-5-niveaus-hoger-dan-advies, vars.$bar-verschil-5);
	@include bar-color(verschil-5, vars.$bar-verschil-5);

	@include bar-color(d-1-niveau-hoger-dan-advies, vars.$bar-verschil-10);
	@include bar-color(d-1-0-niveaus-hoger-dan-advies, vars.$bar-verschil-10);
	@include bar-color(verschil-10, vars.$bar-verschil-10);
	@include bar-color(verschil-10-prognose, rgba(vars.$bar-verschil-10, 0.5));

	@include bar-color(d-1-5-niveaus-hoger-dan-advies, vars.$bar-verschil-15);
	@include bar-color(verschil-15, vars.$bar-verschil-15);

	@include bar-color(d-2-0-niveaus-hoger-dan-advies, vars.$bar-verschil-20);
	@include bar-color(verschil-20, vars.$bar-verschil-20);

	@include bar-color(d-2-5-niveaus-hoger-dan-advies, vars.$bar-verschil-25);
	@include bar-color(verschil-25, vars.$bar-verschil-25);
}

.dashboard-cijfers,
.dashboard-toets,
.dashboard-cijferlijst {
	@include bar-color(d-0-t-m-3, vars.$bar-cijfer-0-3);
	@include bar-color(d-4, vars.$bar-cijfer-4);
	@include bar-color(d-5, vars.$bar-cijfer-5);
	@include bar-color(d-6, vars.$bar-cijfer-6);
	@include bar-color(d-7, vars.$bar-cijfer-7);
	@include bar-color(d-8, vars.$bar-cijfer-8);
	@include bar-color(d-9, vars.$bar-cijfer-9);
	@include bar-color(d-10, vars.$bar-cijfer-10);

	@include bar-color(onvoldoende, vars.$bar-cijfer-onvoldoende);
	@include bar-color(voldoende, vars.$bar-cijfer-voldoende);

	@include bar-color(advies, vars.$bar-cijfer-advies);
}

.dashboard-se-ce-verschil {
	@include bar-color(minus_3_75, #0c212b);
	@include bar-color(minus_3_5, #254c62);
	@include bar-color(minus_3, #3e7594);
	@include bar-color(minus_2_5, #589abd);
	@include bar-color(minus_2, #77b9d9);
	@include bar-color(minus_1_5, #9bd2e5);
	@include bar-color(minus_1, #bde4f1);
	@include bar-color(minus_0_5, #dcf3f8);
	@include bar-color(d-0, #ffffff);
	@include bar-color(d-0_5, #fff2c3);
	@include bar-color(d-1, #ffe382);
	@include bar-color(d-1_5, #ffda5a);
	@include bar-color(d-2, #fdc811);
	@include bar-color(d-2_5, #f5b100);
	@include bar-color(d-3, #d48b00);
	@include bar-color(d-3_5, #ba7000);
	@include bar-color(d-3_75, #7b4a00);
	.areas {
		@include bar-color(dark, vars.$cumlaude-grijs);
		@include bar-color(light, vars.$cumlaude-lichtgrijs);
		@include bar-color(none, none);
	}
	tr.cdk-row.alternating:nth-child(odd) .areas {
		@include bar-color(dark, vars.$cumlaude-donkergrijs);
		@include bar-color(light, vars.$cumlaude-grijs);
	}
}

.dashboard-matrix,
.dashboard-cohortdetails {
	@include cell-color(doorstroom, vars.$bar-doorstroom);
	@include cell-color(opstroom, vars.$bar-opstroom);
	@include cell-color(geslaagd, vars.$bar-geslaagd);
	@include cell-color(geslaagd-prognose, rgba(vars.$bar-geslaagd, 0.5));
	@include cell-color(afstroom, vars.$bar-afstroom);
	@include cell-color(doublure, vars.$bar-doublure);
	@include cell-color(afgewezen, vars.$bar-afgewezen);
	@include cell-color(doublure-afgewezen, vars.$bar-afgewezen);
	@include cell-color(onbekend-alt, vars.$bar-onbekend-alt);
	@include cell-color(instroom, vars.$bar-onbekend-alt);

	span.onbekend,
	span.onbekend-prognose {
		border-color: vars.$color-cl-background-2;
		background-color: vars.$color-cl-background-2;
		&:hover {
			border-color: vars.$color-cl-typography-5;
		}
	}
}

.dashboard-toets,
.dashboard-cijferlijst {
	@include border-color(cijfer-0-3-x, vars.$line-cijfer-0-3);
	@include text-color(cijfer-0-3-x, vars.$line-cijfer-0-3);
	@include border-color(cijfer-4-x, vars.$line-cijfer-4);
	@include text-color(cijfer-4-x, vars.$line-cijfer-4);
	@include border-color(cijfer-5-x, vars.$line-cijfer-5);
	@include text-color(cijfer-5-x, vars.$line-cijfer-5);
	@include border-color(cijfer-5, vars.$line-cijfer-5);
	@include border-color(cijfer-6, vars.$line-cijfer-6);
	@include border-color(cijfer-7, vars.$line-cijfer-7);
	@include border-color(cijfer-8, vars.$line-cijfer-8);
	@include text-color(cijfer-8-x, vars.$line-cijfer-8);
	@include border-color(cijfer-8-x, vars.$line-cijfer-8);
	@include text-color(cijfer-9-x, vars.$line-cijfer-9);
	@include border-color(cijfer-9-x, vars.$line-cijfer-9);
	@include text-color(cijfer-10-x, vars.$line-cijfer-10);
	@include border-color(cijfer-10-x, vars.$line-cijfer-10);
	@include border-color(cijfer-onvoldoende, vars.$line-cijfer-onvoldoende);
	@include border-color(cijfer-voldoende, vars.$line-cijfer-voldoende);
	@include border-color(cijfer-type-advies, vars.$line-cijfer-advies);
}

.dashboard-lesregistraties {
	@include bar-color(huiswerk-niet-in-orde, vars.$bar-huiswerk-niet-in-orde, rgba(vars.$bar-huiswerk-niet-in-orde, 0.1));
	@include bar-color(materiaal-niet-in-orde, vars.$bar-materiaal-niet-in-orde, rgba(vars.$bar-materiaal-niet-in-orde, 0.1));
	@include bar-color(te-laat, vars.$bar-te-laat, rgba(vars.$bar-te-laat, 0.1));
	@include bar-color(verwijderd, vars.$bar-verwijderd, rgba(vars.$bar-verwijderd, 0.1));
	@include bar-color(mobiel, vars.$bar-mobiel, rgba(vars.$bar-mobiel, 0.1));
	@include bar-color(overig, vars.$bar-lesregistratie-overig, rgba(vars.$bar-lesregistratie-overig, 0.1));

	div.bar,
	div.legenda {
		&:not(.huiswerk-niet-in-orde):not(.materiaal-niet-in-orde):not(.te-laat):not(.verwijderd):not(.mobiel) {
			background-color: vars.$bar-lesregistratie-overig;
		}
	}
	polyline:not([class^='huiswerk-niet-in-orde']):not([class^='materiaal-niet-in-orde']):not([class^='te-laat']):not([class^='verwijderd']):not(
			[class^='mobiel']
		) {
		stroke: vars.$bar-lesregistratie-overig;

		&[class$='-fill'] {
			fill: rgba(vars.$bar-lesregistratie-overig, 0.1);
			stroke: none;
		}
	}
}

.dashboard-cohortrendement {
	@include bar-color(zonder-vertraging, vars.$bar-doorstroom);
	@include bar-color(op-niveau, vars.$bar-doorstroom);
	@include bar-color(gedoubleerd, vars.$bar-doublure);
	@include bar-color(boven-advies, vars.$bar-opstroom);
	@include bar-color(opgestroomd, vars.$bar-opstroom);
	@include bar-color(boven-niveau, vars.$bar-opstroom);
	@include bar-color(boven-advies-gedoubleerd, vars.$bar-opstroom-doublure);
	@include bar-color(opgestroomd-gedoubleerd, vars.$bar-opstroom-doublure);
	@include bar-color(onder-advies, vars.$bar-afstroom);
	@include bar-color(onder-niveau, vars.$bar-afstroom);
	@include bar-color(afgestroomd, vars.$bar-afstroom);
	@include bar-color(onder-advies-gedoubleerd, vars.$bar-afstroom-doublure);
	@include bar-color(afgestroomd-gedoubleerd, vars.$bar-afstroom-doublure);
	@include bar-color(afgewezen, vars.$bar-cohortrendement-afgewezen);
	@include bar-color(zonder-vertraging-prognose, rgba(vars.$bar-doorstroom, 0.5));
	@include bar-color(op-niveau-prognose, rgba(vars.$bar-doorstroom, 0.5));
	@include bar-color(gedoubleerd-prognose, rgba(vars.$bar-doublure, 0.5));
	@include bar-color(boven-advies-prognose, rgba(vars.$bar-opstroom, 0.5));
	@include bar-color(opgestroomd-prognose, rgba(vars.$bar-opstroom, 0.5));
	@include bar-color(boven-niveau-prognose, rgba(vars.$bar-opstroom, 0.5));
	@include bar-color(boven-advies-gedoubleerd-prognose, rgba(vars.$bar-opstroom-doublure, 0.5));
	@include bar-color(opgestroomd-gedoubleerd-prognose, rgba(vars.$bar-opstroom-doublure, 0.5));
	@include bar-color(onder-advies-prognose, rgba(vars.$bar-afstroom, 0.5));
	@include bar-color(onder-niveau-prognose, rgba(vars.$bar-afstroom, 0.5));
	@include bar-color(afgestroomd-prognose, rgba(vars.$bar-afstroom, 0.5));
	@include bar-color(onder-advies-gedoubleerd-prognose, rgba(vars.$bar-afstroom-doublure, 0.5));
	@include bar-color(afgestroomd-gedoubleerd-prognose, rgba(vars.$bar-afstroom-doublure, 0.5));
	@include bar-color(afgewezen-prognose, rgba(vars.$bar-cohortrendement-afgewezen, 0.5));
}

.dashboard-uitstroom-iq {
	@include bar-color(iqpunt-1, vars.$bar-iqpunt-1);
	@include bar-color(iqpunt-2, vars.$bar-iqpunt-2);
	@include bar-color(iqpunt-3, vars.$bar-iqpunt-3);
	@include bar-color(iqpunt-4, vars.$bar-iqpunt-4);
	@include bar-color(iqpunt-5, vars.$bar-iqpunt-5);
	@include bar-color(iqpunt-6, vars.$bar-iqpunt-6);
	@include bar-color(iqpunt-7, vars.$bar-iqpunt-7);
	@include bar-color(iqpunt-8, vars.$bar-iqpunt-8);
	@include bar-color(uitstroompunt-5-0, vars.$bar-uitstroompunt-50);
	@include bar-color(uitstroompunt-5-5, vars.$bar-uitstroompunt-55);
	@include bar-color(uitstroompunt-6-0, vars.$bar-uitstroompunt-60);
	@include bar-color(uitstroompunt-7-0, vars.$bar-uitstroompunt-70);
	@include bar-color(uitstroompunt-8-0, vars.$bar-uitstroompunt-80);
	@include bar-color(uitstroompunt-8-5, vars.$bar-uitstroompunt-85);
	@include bar-color(uitstroompunt-9-0, vars.$bar-uitstroompunt-90);
	@include bar-color(uitstroompunt-10-0, vars.$bar-uitstroompunt-100);
	@include bar-color(uitstroompunt-10-5, vars.$bar-uitstroompunt-105);
	@include bar-color(uitstroompunt-11-0, vars.$bar-uitstroompunt-110);
	@include bar-color(uitstroompunt-11-5, vars.$bar-uitstroompunt-115);
	@include bar-color(uitstroompunt-12-0, vars.$bar-uitstroompunt-120);
}

.dashboard-onderwijsresultaten {
	.bovendenormwaarde,
	.onderdenormwaarde {
		width: 0.75rem !important;
		height: 0.75rem !important;
	}

	.bovendenormwaarde {
		@extend .svg-plus-sign;
		background-color: vars.$color-cl-positive-2;
	}
	.onderdenormwaarde {
		@extend .svg-minus-sign;
		background-color: vars.$color-cl-error-3;
	}

	.stijgingvorigjaar,
	.dalingvorigjaar,
	.dalingvorige2jaren {
		width: 0.75rem !important;
		height: 0.75rem !important;
	}
	.stijgingvorigjaar {
		@extend .svg-graph-stijgend;
	}
	.dalingvorigjaar {
		@extend .svg-graph-dalend-warning;
	}
	.dalingvorige2jaren {
		@extend .svg-graph-dalend-error;
	}

	.onderdenormindicator,
	.onderdenorm2jarenindicator,
	.onvoldoende,
	.onvoldoende2jaren {
		height: 0.5rem !important;
		width: 0.5rem !important;
		border-radius: 50%;
		display: inline-block;
	}
	.onvoldoende,
	.onderdenormindicator {
		background-color: vars.$color-cl-warning-1;
	}
	.onvoldoende2jaren,
	.onderdenorm2jarenindicator {
		background-color: vars.$color-cl-error-1;
	}

	.score-onder-norm,
	.score-boven-norm {
		padding: 0.25rem;
		width: 2.5rem;
		border-radius: 2px;

		font-weight: 400;
		font-size: 10px;
		line-height: 12px;
		text-align: left;
	}
	.score-boven-norm {
		background-color: vars.$color-cl-positive-2;
		color: vars.$color-cl-positive-1;
	}
	.score-onder-norm {
		background-color: vars.$color-cl-error-3;
		color: vars.$color-cl-error-1;
	}

	.indicator-1jaar,
	.indicator-2jaren {
		height: 0.5rem !important;
		width: 0.5rem !important;
		border-radius: 50%;
		display: inline-block;
	}
	.indicator-1jaar {
		background-color: vars.$color-cl-warning-1;
	}
	.indicator-2jaren {
		background-color: vars.$color-cl-error-1;
	}

	@include bar-color(score, vars.$color-cl-chart-paars);
	@include bar-color(gecorrigeerde-norm, vars.$color-cl-primary-2);
	@include bar-color(gecorrigeerdenorm, vars.$color-cl-primary-2);
	@include bar-color(landelijke-norm, vars.$color-cl-primary-3);
	@include bar-color(landelijkenorm, vars.$color-cl-primary-3);
}

.dashboard-prestatieanalyse {
	.indicator-1jaar {
		height: 0.5rem !important;
		width: 0.5rem !important;
		border-radius: 50%;
		display: inline-block;
		background-color: vars.$color-cl-warning-1;
	}

	.negatiefhistorie,
	.gemiddeldhistorie,
	.positiefhistorie {
		opacity: 0.2;
	}
	.negatiefhistorie,
	.percentielnegatief {
		background-color: vars.$bar-percentiel-tot-25;
	}
	.gemiddeldhistorie,
	.percentielgemiddeld {
		background-color: vars.$bar-percentiel-25-75;
	}
	.positiefhistorie,
	.percentielpositief {
		background-color: vars.$bar-percentiel-va-75;
	}
	.scorehistorie {
		background-color: vars.$color-cl-primary-1;
	}

	@include bar-color(pv_nr_score, vars.$color-cl-primary-1);
}

.dashboard-prestatieanalyse,
.dashboard-cijfers {
	@include bar-color(percentielnegatief, vars.$bar-percentiel-tot-25);
	@include bar-color(percentielgemiddeld, vars.$bar-percentiel-25-75);
	@include bar-color(percentielpositief, vars.$bar-percentiel-va-75);
	@include bar-color(afwijkingnegatief, vars.$bar-percentiel-tot-25);
	@include bar-color(afwijkingpositief, vars.$bar-percentiel-va-75);
}

.dashboard-basisvaardigheden {
	@include bar-color(onbekend, vars.$bar-onbekend);
	@include bar-color(r00f, vars.$bar-niveau-00f);
	@include bar-color(r05f, vars.$bar-niveau-05f);
	@include bar-color(r10f, vars.$bar-niveau-10f);
	@include bar-color(r15f, vars.$bar-niveau-15f);
	@include bar-color(r20f, vars.$bar-niveau-20f);
	@include bar-color(r25f, vars.$bar-niveau-25f);
	@include bar-color(r30f, vars.$bar-niveau-30f);
	@include bar-color(r35f, vars.$bar-niveau-35f);
	@include bar-color(r40f, vars.$bar-niveau-40f);

	.bar:not(last-of-type) {
		&.r00f,
		&.r05f,
		&.r10f,
		&.r15f,
		&.r20f,
		&.r25f,
		&.r30f,
		&.r35f,
		&.r40f {
			border-right: 1px solid vars.$color-cl-background-3;
		}
	}

	.niveau_gemiddelde {
		background-color: vars.$color-cl-background-3;
		border: 1px solid vars.$color-cl-primary-1;
		height: 0.5rem !important;
		width: 0.5rem !important;
		border-radius: 50%;
		display: inline-block;
	}

	.gemiddelde_leerfase {
		background-color: vars.$color-cl-primary-3;
		border: 1px solid vars.$color-cl-typography-4;
		height: 0.5rem !important;
		width: 0.5rem !important;
		border-radius: 50%;
		display: inline-block;
		box-sizing: content-box;
	}

	.target_gemiddelde {
		background-color: vars.$cumlaude-oranje;
		border: 1px solid vars.$color-cl-background-3;
		height: 0.5rem !important;
		width: 0.5rem !important;
		border-radius: 50%;
		display: inline-block;
	}
}
